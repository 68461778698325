/* styles.css */

/* Typography styles */
.typography-h1 {
  margin-bottom: 2.5rem;
  font-size: 2.25rem; /* text-4xl */
  font-weight: bold;
  color: #4a5568; /* text-gray-700 */
}

.typography-p {
  font-size: 0.875rem; /* text-sm */
}

@media (min-width: 640px) {
  .typography-p {
    font-size: 1rem; /* sm:text-base */
  }
}

@media (min-width: 1024px) {
  .typography-p {
    font-size: 1.125rem; /* lg:text-lg */
  }
}

/* Other existing styles */
.carousel .control-dots .dot {
  background: #00C6BE;
  border: 1px solid #fff;
  box-shadow: none;
  width: 15px;
  height: 15px;
  margin: 0 8px;
  border-radius: 50%;
  display: none;
}

.carousel .control-dots .dot.selected, .carousel .control-dots .dot:hover {
  background: #0062FF;
  box-shadow: none;
}

.control-arrow, .control-next {
  display: none;
}

.gradient {
  background: linear-gradient(180deg,#D9EFF6, #C5ECFD 20%, white 100%,#B9D5FE);
  z-index: -20;
}

.carousel .slide {
  transition: transform 0.5s ease-in-out;
}

.carousel-slide {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* General styles for h1 and p */
.carousel-slide h1 {
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.carousel-slide p {
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

/* Specific styles for different screen sizes */
@media (max-width: 768px) {
  .carousel-slide h1 {
    font-size: 1.5rem; /* Smaller font size for mobile */
  }

  .carousel-slide p {
    font-size: 0.875rem; /* Smaller font size for mobile */
  }

  .button{
    padding: 5px 10px;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .carousel-slide h1 {
    font-size: 2rem; /* Medium font size for tablets */
  }

  .carousel-slide p {
    font-size: 1rem; /* Medium font size for tablets */
  }

  .button{
    padding: 5px 10px;
  }
}

@media (min-width: 1025px) {
  .carousel-slide h1 {
    font-size: 3rem; /* Larger font size for desktops */
  }

  .carousel-slide p {
    font-size: 1.25rem; /* Larger font size for desktops */
  }
}

/* Video container to make video responsive */
.video-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  height: 0;
}

.video-container video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Keyframes for sliding in and out */
@keyframes slide-in {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slide-out {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}

.animate-slide-in {
  animation: slide-in 0.3s forwards;
}

.animate-slide-out {
  animation: slide-out 0.3s forwards;
}
