.payment-form-container {
    max-width: 500px;
    margin: 50px auto;
    padding: 20px;
    background: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-top: 150px;
}

.payment-form-container h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
}

.payment-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.form-group {
    display: flex;
    flex-direction: column;
}

.form-group label {
    margin-bottom: 5px;
    color: #555;
}

.form-group input {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 16px;
    transition: border-color 0.3s;
}

.form-group input:focus {
    border-color: #007bff;
    outline: none;
}

.pay-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.pay-button:hover {
    background-color: #0056b3;
}

.error {
    color: red;
    font-size: 14px;
    margin-top: 5px;
}

/* Responsive Design */
@media (max-width: 768px) {
    .payment-form-container {
        width: 90%;
        margin: 50px auto;
        padding: 15px;
        margin-top: 100px;
    }

    .pay-button {
        font-size: 16px;
        padding: 10px;
    }
}

@media (max-width: 480px) {
    .payment-form-container {
        width: 95%;
        margin: 10px auto;
        padding: 10px;
        margin-top: 100px;
    }

    .form-group input {
        font-size: 14px;
        padding: 8px;
    }

    .pay-button {
        font-size: 16px;
        padding: 10px;
    }
}
