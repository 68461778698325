#app { height: 100% }

.swiper {
  width: 320px;
  height: 240px;
}

.swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 18px;
  font-size: 22px;
  font-weight: bold;
  color: #fff;
}



.card {
  position: relative;
  overflow: hidden;
  border: 2px dotted #00f; /* Water color dotted border */
  border-radius: 10px; /* Slightly rounded corners */
  background: transparent;
  transition: border 0.5s ease-in-out;
}

.card:before {
  content: '';
  position: absolute;
  top: -200%;
  left: -50%;
  width: 200%;
  height: 400%;
  border-radius: 50%;
  z-index: -1;
  transition: all 2s ease-in-out; /* Slower melting effect */
}

.card:hover:before {
  top: 50%;
  left: 50%;
  width: 0;
  height: 0;
  background: rgba(255, 255, 255, 0.1); /* Light white overlay */
  transition: all 3s ease-in-out; /* Slower melting effect */
}

.card:hover {
  border-color: transparent;
}

.card:hover h1, .card:hover p {
  transform: translateY(20px);
  opacity: 0;
  transition: all 0.5s ease-in-out;
}

.card h1, .card p {
  transition: all 0.5s ease-in-out;
}

.animation-text {
  display: inline-block;
  animation: bounce-animation 2s infinite;
}

@keyframes bounce-animation {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px);
  }
  60% {
    transform: translateY(-10px);
  }
}

.rotate-letter {
  display: inline-block;
  animation: rotate-letter-animation 2s infinite;
}

@keyframes rotate-letter-animation {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(15deg);
  }
  50% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(-15deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.wave-animation {
  display: inline-block;
  font-size: 50px;
  font-weight: bold;
  letter-spacing: 7px;
  cursor: pointer;
  text-transform: uppercase;
  color: white;
  animation: wave 3s infinite ease-in-out;
}

@keyframes wave {
  0%, 100% {
    transform: translateY(0);
  }
  70% {
    transform: translateY(-10px);
  }
}

.splash-animation {
  display: inline-block;
  font-size: 20px;
  font-weight: normal;
  color: white;
  animation: splash 3s infinite ease-in-out;
}

@keyframes splash {
  0%, 100% {
    transform: translateY(0);
    opacity: 1;
  }
  70% {
    transform: translateY(-5px);
    opacity: 0.7;
  }
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap');

.button {
  position: relative;
  overflow: hidden;
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  font-family: 'Poppins', sans-serif;
  transition: background-color 0.3s, transform 0.3s;
}

.button:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

.button:before {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  width: 200%;
  height: 100%;
  background: rgba(255, 255, 255, 0.2);
  transform: translateX(-50%) rotate(45deg);
  transition: transform 0.5s ease-in-out;
  pointer-events: none;
  z-index: 0;
}

.button:hover:before {
  transform: translateX(-50%) rotate(45deg) translateY(-30%);
}

.button:focus {
  outline: none;
}

.button-text {
  position: relative;
  z-index: 1;
  display: inline-block;
  transition: transform 0.3s;
}

.button:hover .button-text {
  animation: zoom-text 0.5s forwards;
}

@keyframes zoom-text {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}

/* Adjust button size for smaller screens */
@media (max-width: 768px) {
  .button {
    padding: 5px 10px;
    font-size: 12px;
  }
}
