@tailwind base;
@tailwind components;
@tailwind utilities;

/* Apply the custom font globally */
body {
  @apply font-custom2;
  overflow-x: hidden;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Beinale',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1, h2, h3, h4, h5, h6, p, a, span, div {
  @apply font-custom2;
}

@font-face {
  font-family: "Custom-2";
  src: url("./assets/fonts/Biennale.ttf");
}

