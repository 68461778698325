.carousel-container {
}

.carousel-item {
}

.rounded-full {
    border-radius: 50%; /* Make the image appear as a rounded circle */
}

.image-width {
    /*width: 200px;*/
    /*height: 200px;*/
}

